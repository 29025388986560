import SlotMachine from '..';
import { BonusStatus, EventTypes, GameMode, UserBonus, freeRoundBonusId } from '../../global.d';
import {
  setCurrentBonus,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setGameMode,
  setIsFreeRoundBonus,
  setIsTimeoutErrorMessage,
  setNextResult,
  setStressful,
} from '../../gql/cache';
import i18n from '../../i18next';
import { calcActiveUserBonusTotalWinAmount, isBaseGameMode, isFreeSpinsMode } from '../../utils';
import { SlotMachineState, eventManager } from '../config';

import { FreeSpinEndState } from './d';
import { FreeRoundsPopup } from './freeRoundsPopup';
import { FreeRoundsEndPopup } from './freeRoundsPopupEnd';
import { getFreeRoundBonus, getUserActiveBonuses, isGetFreeSpinBonus } from './helper';

const finishedFreeRounds = () => {
  return (
    setFreeRoundBonus().currentRound === setFreeRoundBonus().rounds &&
    setFreeRoundBonus().isActive &&
    !setCurrentBonus().isActive
  );
};

const openEndPopup = () => {
  if (!setFreeRoundBonus().isActive) return;
  setFreeRoundBonus({ ...setFreeRoundBonus(), isActive: false });
  eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS_END);
};

export class FreeRoundBonus {
  private freeRoundsPopup: FreeRoundsPopup;

  private freeRoundsEndPopup: FreeRoundsEndPopup;

  private freeSpinEndState: FreeSpinEndState = 'none';

  private freeRoundBonusAtEndOfFreeSpins: UserBonus | null = null;

  constructor() {
    this.freeRoundsPopup = new FreeRoundsPopup();
    this.freeRoundsEndPopup = new FreeRoundsEndPopup();

    if (setFreeRoundBonus().isActive) {
      console.log('setFreeSpinBonus:constructor');
      setIsFreeRoundBonus(true);
      if (!setCurrentBonus().isActive) {
        eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS, setFreeRoundBonus().rounds);
      } else {
        if (setFreeRoundsTotalWin() > 0) {
          setTimeout(() => {
            eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
          }, 100);
        }
      }
    }
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.slotMachineStateChange.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, () => {
      if (!setIsFreeRoundBonus()) return;
      if (setIsTimeoutErrorMessage()) return;
      this.updateRounds();
    });
    eventManager.addListener(EventTypes.SET_FREESPINS_VIEW, () => {
      this.freeSpinEndState = 'none';
    });

    eventManager.addListener(EventTypes.FREE_ROUND_BONUS_EXPIRE_CHECK, async () => {
      if (isFreeSpinsMode(setGameMode())) return;
      if (!setIsFreeRoundBonus()) return;

      const bonuses = await getUserActiveBonuses();
      const freeRoundBonus = bonuses.data.userBonuses.find((e) => e.bonusId === freeRoundBonusId) as UserBonus;
      this.freeRoundBonusAtEndOfFreeSpins = freeRoundBonus;
      const isFreeRoundBonus = freeRoundBonus;
      // TODO(FRB) for test of expire
      //const isFreeRoundBonus = false;
      const remainingRounds = setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound;
      const totalWin = setFreeRoundsTotalWin();
      eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, totalWin);

      if (!isFreeRoundBonus && remainingRounds > 0) {
        this.freeSpinEndState = 'none';

        setStressful({
          show: true,
          type: 'network',
          message: i18n.t('errors.OPERATOR.INVALID_BONUS'),
          callback: () => {
            setFreeRoundBonus({
              ...setFreeRoundBonus(),
              isActive: false,
            });
            eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS_END, true);
          },
        });
      }
    });

    eventManager.addListener(EventTypes.END_FREESPINS, async () => {
      this.freeSpinEndState = 'regular';

      if (!setIsFreeRoundBonus()) return;

      if (setNextResult() === undefined || setNextResult() === null) return;

      const bonuses = await getUserActiveBonuses();
      const freeRoundBonus = bonuses.data.userBonuses.find((e) => e.bonusId === freeRoundBonusId) as UserBonus;
      this.freeRoundBonusAtEndOfFreeSpins = freeRoundBonus;
      const isFreeRoundBonus = freeRoundBonus;
      // TODO(FRB) for test of expire
      //const isFreeRoundBonus = false;
      const remainingRounds = setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound;
      if (!isFreeRoundBonus && remainingRounds > 0) {
        this.freeSpinEndState = 'expireFreeRoundBonus';
      }
      //if (setNextResult()) {
      const totalWin = setFreeRoundsTotalWin();
      //const winAmount = setNextResult()!.bet.result?.winCoinAmount;
      eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, totalWin);
      //}
    });
  }

  private updateRounds() {
    if (setFreeRoundBonus().isActive && !isFreeSpinsMode(setGameMode())) {
      setFreeRoundBonus({
        ...setFreeRoundBonus(),
        currentRound: setFreeRoundBonus().currentRound + 1,
      });
      eventManager.emit(
        EventTypes.UPDATE_FREE_ROUNDS_LEFT,
        setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound,
      );
    }
  }

  private slotMachineStateChange(state: SlotMachineState) {
    if (state !== SlotMachineState.IDLE) return;

    const nextResult = SlotMachine.getInstance().nextResult; //setNextResult();
    if (!nextResult) return;

    const gameMode = setGameMode();

    if (!setIsFreeRoundBonus()) {
      if (isBaseGameMode(gameMode) && !isGetFreeSpinBonus(nextResult) && this.freeSpinEndState === 'none') {
        // TODO(FRB) Be sure to turn it ON when pushing to master
        const freeRoundBonus = getFreeRoundBonus(nextResult);
        // TODO(FRB) Be sure to turn it OFF when pushing to master
        /* const freeRoundBonus: UserBonus = {
          ...setCurrentBonus(),
          rounds: 2,
        }; */

        if (freeRoundBonus && freeRoundBonus.status !== BonusStatus.SETTLED) {
          const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeRoundBonus);
          setFreeRoundBonus({
            id: freeRoundBonus.id,
            bonusId: freeRoundBonus.bonusId,
            coinAmount: freeRoundBonus.coinAmount,
            coinValue: freeRoundBonus.coinValue,
            status: freeRoundBonus.status,
            rounds: freeRoundBonus.rounds,
            totalWinAmount: totalWinAmount,

            isActive: true,
            currentRound: 0,
          });
          console.log('FRBRoundBonus:', freeRoundBonus, freeRoundBonus.status); //test

          setFreeRoundsTotalWin(totalWinAmount);

          eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
          eventManager.emit(EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS, {
            inDuration: 2000,
            outDuration: 1000,
            callback: () => {
              console.log('setFreeSpinBonus:stateChangeCallback');
              setIsFreeRoundBonus(true);
              eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS, setFreeRoundBonus().rounds);
              eventManager.emit(
                EventTypes.UPDATE_FREE_ROUNDS_LEFT,
                setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound,
              );
            },
          });
        }
      }
      return;
    }
    if (!setIsFreeRoundBonus()) return;

    if (state === SlotMachineState.IDLE) {
      const nextResult = setNextResult();
      if (nextResult && nextResult != null) {
        /*
        const winAmount = setNextResult()!.bet.result.winCoinAmount;
        updateFreeRoundsTotalWin(winAmount);
*/
        //check
        /*
        if (winAmount > 0) {
          setFreeRoundsTotalWin(setFreeRoundsTotalWin() + winAmount);
          eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
        }
          */

        const gameMode = setGameMode();
        if (finishedFreeRounds()) {
          if (isBaseGameMode(gameMode) && !isGetFreeSpinBonus(nextResult!)) {
            if (this.freeSpinEndState === 'none') {
              openEndPopup();
            }
          }
        }
      }
    }
  }

  private onChangeMode(settings: { mode: GameMode }) {
    const mode = settings.mode;
    if (setIsFreeRoundBonus() && isFreeSpinsMode(mode)) {
      if (setFreeRoundsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      }
    } else {
      console.log(this.freeSpinEndState);
      if (this.freeSpinEndState === 'expireFreeRoundBonus') {
        setStressful({
          show: true,
          type: 'network',
          message: i18n.t('errors.OPERATOR.INVALID_BONUS'),
          callback: () => {
            setFreeRoundBonus({
              ...setFreeRoundBonus(),
              isActive: false,
            });
            eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS_END, true);
          },
        });
      } else if (finishedFreeRounds()) {
        openEndPopup();
      } else if (!this.freeRoundsPopup.isOnceOpened) {
        if (setIsFreeRoundBonus()) {
          setFreeRoundsTotalWin(calcActiveUserBonusTotalWinAmount(this.freeRoundBonusAtEndOfFreeSpins!));

          eventManager.emit(
            EventTypes.OPEN_POPUP_FREE_ROUNDS,
            setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound,
          );
        }
      }
      this.freeSpinEndState = 'none';
    }
  }
}
