import { gql } from '@apollo/client';

export const authGql = gql`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      sessionId
    }
  }
`;

export const versionGql = gql`
  mutation Version {
    version {
      version
      time
    }
  }
`;

export const placeBetGql = gql`
  mutation PlaceBet($input: PlaceBetInput!) {
    placeBet(input: $input) {
      bet {
        id
        coinAmount
        coinValue
        reelSet {
          id
          type
        }
        lineSet {
          id
          coinAmountMultiplier
        }
        result {
          reelPositions
          winCoinAmount
        }
        data
      }
      paylines {
        lineId
        winPositions
        amount
      }
      balance {
        placed {
          amount
          currency
          metadata {
            type
            data
          }
        }
        settled {
          amount
          currency
          metadata {
            type
            data
          }
        }
      }
      rewards {
        ... on BetBonusReward {
          userBonusId
          userBonus {
            id
            bonus {
              id
              reelSetId
              type
              coinAmount
            }
            rounds
            roundsPlayed
            coinValue
            data
            betId
            totalWinAmount
            status
          }
        }
        ... on BetCoinReward {
          amount
        }
      }
    }
  }
`;

export const buyBonusGql = gql`
  mutation buyBonus($input: BuyBonusesInput!) {
    buyBonus(input: $input) {
      id
      bonusId
      status
      coinValue
      data
      balance {
        amount
        currency
      }
      rounds
      roundsPlayed
    }
  }
`;

export const activateUserBonus = gql`
  mutation activateUserBonus($input: ActivateUserBonusInput!) {
    activateUserBonus(input: $input) {
      status
    }
  }
`;
